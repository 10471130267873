<template>
   <div class="my-10">
      <div class="md:max-w-xl mx-auto">
         <template v-if="!isSetOtp">
            <div class="mx-5 md:mx-auto pt-6 pb-10 px-10 md:w-full bg-white rounded-md shadow-md">
               <h3 class="text-3xl font-semibold text-indigo-900 mt-2 mb-8 sm:mb-10">
                  Create an account
               </h3>
               <div class="">
                  <ValidationObserver v-slot="{ handleSubmit }">
                     <form class="mt-5" @submit.prevent="handleSubmit(requestOtp)">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                           <ValidationProvider tag="div" class="" name="first name" rules="required" v-slot="{ errors }">
                              <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">First name</label>
                              <input type="text" v-model="form.first_name"
                                 class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                                 :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                                 spellcheck="false"/>
                                 <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <ValidationProvider tag="div" name="last name" rules="required" v-slot="{ errors }">
                              <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Last name</label>
                              <input type="text" v-model="form.last_name"
                                 class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                                 :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                                 spellcheck="false"/>
                                 <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <ValidationProvider tag="div" class="col-span-2" name="email" rules="required|email" v-slot="{ errors }">
                              <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Email</label>
                              <input type="email" v-model="form.email"
                                 class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                                 :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                                 spellcheck="false"/>
                                 <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <ValidationProvider tag="div" name="phone number" rules="required" class="col-span-2" v-slot="{ errors }">
                              <label class="text-gray-700 font-semibold">Your phone number</label>
                              <MazPhoneNumberInput v-model="form.phone" default-country-code="NG" class="mt-2" :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                              <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <ValidationProvider tag="div" name="password" rules="required|min:8" vid="confirmation" v-slot="{ errors }">
                              <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Password</label>
                              <input type="password" v-model="form.password" class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                                 autocomplete="new-password" autocapitalize="off" spellcheck="false"
                                 :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                              <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <ValidationProvider tag="div" name="confirm password" rules="required|confirmed:confirmation" v-slot="{ errors }">
                              <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Confirm Password</label>
                              <input type="password" v-model="form.password_confirmation" class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                                 autocomplete="new-password" autocapitalize="off" spellcheck="false"
                                 :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                              <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <div class="relative col-span-2 mt-6">
                              <button type="submit" class="inline-flex items-center cursor-pointer transition 
                                 ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded-md 
                                 focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-3 md:px-6 bg-indigo-900 
                                 hover:bg-indigo-800 w-full mt-1.5">
                              <svg v-if="isLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                 <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                 <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg> Sign Up</button>
                           </div>
                        </div>
                     </form>
                  </ValidationObserver>
                  <div class="font-semibold text-center mt-5 mb-1 text-sm text-gray-500">
                     Already a memeber?
                     <router-link to="/auth/sign-in" class="text-indigo-900 font-bold">Sign In</router-link>
                  </div>
               </div>
            </div>
          </template>
          <template v-else>
             <div class="mx-5 md:mx-auto p-10 pb-12 my-24 md:w-full bg-white rounded-md shadow-md">
                <h3 class="text-2xl text-center font-semibold text-indigo-900 mt-2 mb-8 sm:mb-10">
                  Enter OTP sent to your email
               </h3>
               <div class="text-center flex justify-center">
                  <v-otp-input ref="otpInput"
                     input-classes="otp-input"
                     v-model="form.otp"
                     separator=" - "
                     :num-inputs="6"
                     :should-auto-focus="true"
                     :is-input-num="true"
                     @on-change="handleOnChange"
                     @on-complete="handleOnComplete"
                  />
               </div>
               <div class="relative flex justify-center mt-10">
                  <button @click.prevent="isSetOtp = false" class="px-10 mx-3 font-medium py-2 leading-5 text-gray-800 transition-colors duration-200 transform bg-gray-300 rounded-md hover:bg-gray-400 focus:outline-none">Back</button>
                     <button type="submit" @click.prevent="handleOnComplete()" class="inline-flex items-center cursor-pointer transition 
                        ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded-md 
                        focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-3 md:px-6 bg-indigo-900 
                        hover:bg-indigo-800">
                     <svg v-if="isLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                     </svg> <span v-if="!isLoading">Verify</span><span v-else>Verifying</span></button>
                  </div>
             </div>
          </template>
         <template v-if="isTransactionActive == true">
            <router-link to="/transaction/start" class="flex font-semibold justify-center text-indigo-900 text-base py-5 cursor-pointer hover:opacity-90 transition">
               <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
               </svg>
               <div class="mx-3">Back to transaction</div>
            </router-link>
         </template>
      </div>
   </div>
</template>

<script>
export default {
   name: "Register",

   data() {
      return {
         form: {
            platform: "web"
         },
         isTransactionActive: false,
         isLoading: false,
         isSetOtp: false
      }
   },

   methods: {

      getTransactionData() {
         let getRole = window.localStorage.getItem('transaction-role');
         getRole = JSON.parse(getRole)

         return getRole
      },

      async requestOtp() {
         this.isLoading = true

         try {
            let res = await this.$store.dispatch('auth/request_otp', this.form)
            if(res.status == 'success') {
               this.isSetOtp = true
               this.$toast.success(res.message)
            }
            this.isLoading = false
         } catch(error) {
            this.$toast.error(error.response.data.message)
            this.isLoading = false
         }
      },

      handleOnChange() {

      },

      async handleOnComplete() {
         this.isLoading = true
         this.form.otp = this.$refs.otpInput.otp.join("")

         try{
            let res =  await this.$store.dispatch('auth/verify_otp', this.form)
            if(res.status == 'success') {
               this.register()
               this.$toast.success(res.message)
            }
            this.isLoading = false
         } catch(error) {
            this.$toast.error(error.response.data.message)
            this.isLoading = false
         }
      },
      
      async register() {
         this.isLoading = true
         
         try {
            let res = await this.$store.dispatch('auth/register', this.form)
            
            this.$toast.success(res.message)
            this.isLoading = true
            this.$router.push({ name: 'UserLoading'})
         } catch (err) {
            this.isLoading = false
            if(err.response && err.response.status == 400 || err.response.status == 422) {
					this.$toast.error(
						err.response.data.errors.email ?
						err.response.data.errors.email[0] :
						err.response.data.errors.phone ? 
						err.response.data.errors.phone[0] :
                  ''                 
					)
				}
         }
      }
   },

   mounted() {
      if(this.getTransactionData() != null) {
         this.isTransactionActive = true
      }
   }
}
</script>

<style>
.otp-input {
   @apply w-12 h-12 rounded text-center;
}

.otp-input .error {
   @apply border bg-red-500
}
.otp-input::-webkit-inner-spin-button, .otp-input::-webkit-outer-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
</style>